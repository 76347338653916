var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VueCountdownTimer',{attrs:{"start-time":_vm.inicio,"end-time":_vm.fim,"interval":1000,"start-label":_vm.$t('eventos.inicia'),"end-label":_vm.$t('eventos.termina'),"label-position":"begin","end-text":_vm.$t('eventos.finalizado'),"day-txt":'d',"hour-txt":'h',"minutes-txt":'m',"seconds-txt":'s'},on:{"end_callback":function($event){return _vm.endCallBack()}},scopedSlots:_vm._u([{key:"start-label",fn:function(scope){return [(
        scope.props.startLabel !== '' &&
          scope.props.tips &&
          scope.props.labelPosition === 'begin'
      )?_c('span',{staticStyle:{"color":"red"}},[_c('strong',[_vm._v(_vm._s(scope.props.startLabel)+":")])]):_vm._e(),(
        scope.props.endLabel !== '' &&
          !scope.props.tips &&
          scope.props.labelPosition === 'begin'
      )?_c('strong',[_vm._v(_vm._s(scope.props.endLabel)+":")]):_vm._e(),_c('br')]}},{key:"countdown",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.props.days))]),_vm._v(_vm._s(scope.props.dayTxt)+" "),_c('span',[_vm._v(_vm._s(scope.props.hours))]),_vm._v(_vm._s(scope.props.hourTxt)+" "),_c('span',[_vm._v(_vm._s(scope.props.minutes))]),_vm._v(_vm._s(scope.props.minutesTxt)+" "),_c('span',[_vm._v(_vm._s(scope.props.seconds))]),_vm._v(_vm._s(scope.props.secondsTxt)+" ")]}},{key:"end-label",fn:function(scope){return [(
        scope.props.startLabel !== '' &&
          scope.props.tips &&
          scope.props.labelPosition === 'end'
      )?_c('span',[_c('strong',[_vm._v(_vm._s(scope.props.startLabel)+":")])]):_vm._e(),(
        scope.props.endLabel !== '' &&
          !scope.props.tips &&
          scope.props.labelPosition === 'end'
      )?_c('span',[_c('strong',[_vm._v(_vm._s(scope.props.endLabel)+":")])]):_vm._e()]}},{key:"end-text",fn:function(scope){return [_c('span',{staticClass:"red"},[_c('strong',[_vm._v(_vm._s(scope.props.endText))])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }