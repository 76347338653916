<template>
    <div>
        <br />
        <!-- <ListLocais /> -->
        <HoraLocal :title="event.title" />

        <Loading :active="loading" />
        <!-- <ListLocais /> -->
        <a class="voltar" @click.prevent="$router.back()">
            <i class="fa fa-arrow-left" />
        </a>
        <meta property="og:description" :content="event.title" />
        <meta property="og:image" :content="`${$url}/storage/${event.img}`" />
        <img class="img-hora" :src="`${$url}/storage/${event.img}`" v-if="event.img" />

        <img class="img-hora" :src="`${$url}/storage/${event.img1}`" v-if="event.img1" />
        <video class="img-hora" playsinline autoplay loop v-if="event.img2">
            <source :src="`${$url}/storage/${event.img2}`" type="video/mp4" />
        </video>

        <!-- <div class="infografico" v-if="event.img1 || event.img2">
      {{ $t('eventos.infografia') }}:
      <a href="https://www.instagram.com/horadogo/" target="_black"
        >@horadogo</a
      >
    </div> -->

        <div class="content-input">
            <input class="search" type="text" v-model="search" :placeholder="$t('locais.pesquisar')" />
            <div class="limpar" @click.prevent="search = ''">
                <i class="fa fa-trash" />
            </div>
        </div>
        <Loading :active="loadingLocal" />
        <div v-for="(l, k) in locals" :key="k">
            <div class="container" v-if="!loadingLocal">
                <div>
                    <div class="content" @click="l.open = !l.open" :class="{ active: l.open }">
                        <div class="left">
                            <div class="gmt">{{ l.timezone }}</div>
                            <div class="flex flex-wrap">
                                <img v-for="(p, kp) in l.paises" :key="kp" class="mr-1 h-6" :src="p.img" />
                            </div>
                        </div>
                        <div class="hora">
                            <div class="hora-content">
                                <!-- <div class="hora-date">{{ l.date }}</div> -->
                                <div>
                                    <strong>{{ $t('locais.eventos.subtitle') }}</strong>
                                </div>
                                <div>{{ moment(l.dateNew).format('ddd, DD [de] MMM') }}</div>
                                <div class="hora">
                                    {{ moment(l.dateNew).format('HH:mm:ss') }}
                                </div>
                            </div>
                            <div class="hora-inicio">
                                <div class="content-inicio">
                                    <div>{{ $t('eventos.inicio') }}</div>
                                    <div>
                                        {{
                                        moment(new Date(`${event.inicio} ${l.zone}`)).format(
                                        'ddd, DD [de] MMM HH:mm'
                                        )
                                        }}
                                    </div>
                                </div>
                                <br />
                                <div class="content-fim">
                                    <div>{{ $t('eventos.fim') }}</div>
                                    <div>
                                        {{
                                        moment(new Date(`${event.fim} ${l.zone}`)).format(
                                        'ddd, DD [de] MMM HH:mm'
                                        )
                                        }}
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="hora-content">
                <div class="hora-local">{{ $t('locais.inicio') }}</div>
                <div class="hora-date">{{ l.count }}</div>
              </div> -->
                        </div>
                        <div class="down"><i class="fa fa-angle-down"></i></div>
                    </div>
                    <div class="hora-termina">
                        <Termina :inicio="moment(new Date(`${event.inicio} ${l.zone}`))"
                            :fim="moment(new Date(`${event.fim} ${l.zone}`))" />
                    </div>
                </div>
                <collapse-transition>
                    <div class="open" :class="l.open ? 'open-up' : 'open-down'" v-show="l.open">
                        <div v-for="(p, kp) in l.paises" :key="kp" class="open-content">
                            <div class="text-gray-800 font-bold text-sm uppercase mb-2 flex justify-center items-center">
                                <img class="mr-1 h-8" :src="p.img" />{{ p.pais }}
                            </div>
                            <div>
                                <div v-for="(c, ck) in p.location" :key="ck">
                                    <div class="coord-content">
                                        <div class="coord-texts">
                                            <div class="city">{{ c.city }}</div>
                                            <div class="coord-text">
                                                {{ c.coord }}
                                            </div>
                                            <div class="options">
                                                <div class="recomendado" v-if="c.recomendado">Recomendado</div>
                                                <div class="novo" v-if="c.new">Novo</div>
                                            </div>
                                        </div>
                                        <div class="coord-btn">
                                            <vue-flip active-click width="100px" height="45px" class="btn-content">
                                                <template v-slot:front>
                                                    <div class="btn" @click="getCopy(c.coord)">
                                                        {{ $t('locais.copiar') }}
                                                    </div>
                                                </template>
                                                <template v-slot:back>
                                                    <div class="btn" @click="getCopy(c.coord)">=)</div>
                                                </template>
                                            </vue-flip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </collapse-transition>
            </div>
        </div>
        <a class="voltar" @click.prevent="$router.back()">
            <i class="fa fa-arrow-left" />
        </a>
    </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import 'moment-timezone'
import 'moment-countdown'
import countdown from 'countdown'
import VueFlip from 'vue-flip'
import Loading from '../components/Loading'
//import localData from '../components/Locais'
import '@fortawesome/fontawesome-free/css/all.css'
//import ListLocais from '../components/ListLocais'
import HoraLocal from '../components/HoraLocal'
import Termina from '../components/Termina.vue'
import { CollapseTransition } from '@ivanv/vue-collapse-transition'

countdown.setLabels(
  ' milissegundo|s|m|h|d| semana| mês| ano| década| século| milênio',
  ' milissegundos|s|m|h|d| semanas| meses| anos| décadas| séculos| milênios',
  ' ',
  ' ',
  'agora',
  e => String(e).padStart(2, '0')
)

export default {
  components: {
    VueFlip,
    Loading,
    HoraLocal,
    Termina,
    CollapseTransition
  },
  data: () => ({
    local: [],
    loading: false,
    loadingLocal: false,
    search: '',
    event: {},
      localData: []
  }),
  computed: {
    locals() {
      if (this.search) {
        return this.local.filter(l => {
          let r = l.paises.findIndex(p => {
            return p.filtro
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(
                this.search
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
              )
          })
          return l.paises[r]
        })
      }
      return this.local
    }
  },
  methods: {
    moment,
    nextDay(w) {
      let today = new Date()
      let todayN = today.getDay()
      let offset = (todayN < w ? w : 10) - todayN
      today.setDate(today.getDate() + offset)
      return today
    },
    getHoras() {
      this.local.map(i => {
        i.date = moment()
          .tz(i.paises[0].zone)
          .format('HH:mm:ss ‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎DD/MM/YY')
        i.dateNew = moment().tz(i.paises[0].zone)
        i.count = moment(
          moment()
            .tz(i.paises[0].zone)
            .format('YYYY-MM-DD HH:mm:ss')
        )
          .countdown(
            moment(this.nextDay(9))
              .set({
                hour: 18,
                minute: 0,
                second: 0
              })
              .format('YYYY-MM-DD HH:mm')
          )
          .toString()
        return i
      })
      this.loading = false
    },
    getCopy(coord) {
      this.$copyText(coord).then(
        () => null,
        () => alert('Erro')
      )
    },
    async getLocais() {
        if(this.event.lugares) {
            this.loadingLocal = true
            let localData = await axios(`${this.$url}/lugares/${this.$route.params.id}`)
            if (localData.data.length) {
                    this.localData = localData.data.map(l => {
                        l.date = null
                        l.dateFormat = null
                        l.dateHours = null
                        l.dateNew = null
                        l.open = false
                        l.count = null
                        l.d = moment().tz(l.paises[0].zone)
                        l.paises.map(p => {
                            p.filtro = [p.pais].concat(p.city).toString()
                            return {
                                zone: p.zone,
                                pais: p.pais,
                                img: p.img,
                                filtro: p.filtro
                            }
                        })
                        return l
                    })
                }
                this.local = this.localData
        } else {
            this.loadingLocal = true
            let localData = await axios(`${this.$url}/lugares`)
                if (localData.data.length) {
                    this.localData = localData.data.map(l => {
                        l.date = null
                        l.dateFormat = null
                        l.dateHours = null
                        l.dateNew = null
                        l.open = false
                        l.count = null
                        l.d = moment().tz(l.paises[0].zone)
                        l.paises.map(p => {
                            p.filtro = [p.pais].concat(p.city).toString()
                            return {
                                zone: p.zone,
                                pais: p.pais,
                                img: p.img,
                                filtro: p.filtro
                            }
                        })
                        return l
                    })
                }
        }
        this.loadingLocal = false
        this.local = this.localData
    },
    async getDados() {
      const list = await axios(`${this.$url}/eventos/${this.$route.params.id}`)
      this.event = list.data
      this.getLocais()
    }
  },
  mounted() {
    document.title = `${this.$route.meta.lang} CoordsGo.com - ${this.$route.meta.titulo}`


    this.loading = true
    this.getDados()
    setInterval(this.getHoras, 1000)
  }
}
</script>

<style lang="scss" scoped>
.btn {
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.btn-content {
    margin: 0 auto;
}

.container {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 15px 0;

    .down {
        display: flex;
        align-items: center;

        i.active {
            color: #fff;
        }
    }

    .content {
        display: flex;
        padding: 10px;
        cursor: pointer;

        .left {
            flex: 0.7;

            .gmt {
                margin-left: 7px;
                margin-bottom: 7px;
                font-size: 10px;
                color: #fff;
                background-color: #673bb7;
                width: 65px;
                display: flex;
                justify-content: center;
                border-radius: 5px;
            }
        }

        .hora {
            flex: 2;
            display: flex;
            font-size: 11px;
            color: #2c3e50;

            .hora-content {
                flex: 1;
                display: flex;
                flex-direction: column;
                font-size: 11px;
                justify-content: center;
                align-items: center;

                .hora {
                    font-size: 18px;
                    display: flex;
                    justify-content: center;
                    font-weight: bold;
                }
            }

            .hora-inicio {
                flex: 2;
                font-size: 11px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0 10px;

                .content-inicio {
                    border-left: 3px solid #ccc;
                    border-top-left-radius: 0.2rem;
                    border-bottom-left-radius: 0.2rem;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-left: 10px;
                }

                .content-fim {
                    border-left: 3px solid #ccc;
                    border-top-left-radius: 0.2rem;
                    border-bottom-left-radius: 0.2rem;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-left: 10px;
                }
            }
        }
    }

    .hora-termina {
        flex: 1;
        border-top: 1px solid #ccc;
        padding: 5px;
        font-size: 15px;
        background-color: #f9f9f9;
        border-radius: 0 0 4px 4px;
    }

    .active {
        background-color: #673ab7;
        border-radius: 5px 5px 0 0;

        .hora {
            color: #fff;
        }

        .down {
            i {
                color: #fff;
            }
        }

        .left {
            .gmt {
                color: #fff;
            }
        }

        .hora {
            .hora-local {
                color: #fff;
            }

            .hora-date {
                color: #fff;
            }
        }
    }
}

.hora-content {
    display: flex;

    .hora-local {
        flex: 0.8;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .hora-date {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.open-content {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ccc;
    padding-top: 10px;

    .pais {
        color: #2c3e50;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .city {
        color: #2c3e50;
        font-size: 12px;
        text-align: center;
        font-weight: bold;
    }

    .coord-content {
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;

        .coord-texts {
            flex: 2;

            .coord-text {
                color: #2c3e50;
                font-size: 14px;
            }
        }

        .coord-btn {
            flex: 1;

            a {
                display: flex;
                width: 80px;
                color: #fff;
                background-color: #673ab7;
                text-decoration: none;
                padding: 10px 15px;
            }
        }
    }
}

.img {
    height: 12px;
    margin: 0 2px 0 0;
}

.search {
    display: block;
    border: 1px solid #ccc;
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
}

.content-input {
    position: relative;
    display: flex;

    .limpar {
        position: absolute;
        right: 0;
        top: 0;
        padding: 6px 16px;
        cursor: pointer;
    }
}

.options {
    display: flex;
    .recomendado {
        font-size: 10px;
        border-radius: 10px;
        padding: 1px 6px;
        background-color: #2664fb;
        color: #fff;
        margin: 0 5px;
    }
    .novo {
        font-size: 10px;
        border-radius: 10px;
        padding: 1px 6px;
        background-color: #f44336;
        color: #fff;
        margin: 0 5px;
    }
}
</style>

<style lang="scss">
.back {
    background-color: #ffc107;
}

.front {
    background-color: #673ab7;
}

.back,
.front {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 100%;
}

.back {
    transform: rotateY(180deg);
}

.back,
.front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.back,
.flipper,
.front {
    transform-style: preserve-3d;
    border-radius: 5px;
}

*,
:after,
:before {
    border-width: 0;
    border-style: solid;
    border-color: #e2e8f0;
}

button,
input {
    padding: 0;
    line-height: inherit;
    color: inherit;
}

button,
input {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
}

.fa,
.fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.fa,
.fas,
.far,
.fal,
.fad,
.fab {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.img-hora {
    max-width: 349px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.voltar {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    width: 100px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #673bb7;
    cursor: pointer;

    i {
        font-size: 40px;
        color: #673bb7;
    }
}

.infografico {
    text-align: right;
    font-size: 12px;
    margin-bottom: 20px;

    a {
        text-decoration: none;
        font-weight: bold;
        color: #673ab7;
    }
}
</style>
