<template>
  <VueCountdownTimer
    :start-time="inicio"
    :end-time="fim"
    :interval="1000"
    :start-label="$t('eventos.inicia')"
    :end-label="$t('eventos.termina')"
    label-position="begin"
    :end-text="$t('eventos.finalizado')"
    :day-txt="'d'"
    :hour-txt="'h'"
    :minutes-txt="'m'"
    :seconds-txt="'s'"
    @end_callback="endCallBack()"
  >
    <template slot="start-label" slot-scope="scope">
      <span
        style="color: red"
        v-if="
          scope.props.startLabel !== '' &&
            scope.props.tips &&
            scope.props.labelPosition === 'begin'
        "
        ><strong>{{ scope.props.startLabel }}:</strong></span
      >
      <strong
        v-if="
          scope.props.endLabel !== '' &&
            !scope.props.tips &&
            scope.props.labelPosition === 'begin'
        "
        >{{ scope.props.endLabel }}:</strong
      >
      <br />
    </template>

    <template slot="countdown" slot-scope="scope">
      <span>{{ scope.props.days }}</span
      >{{ scope.props.dayTxt }} <span>{{ scope.props.hours }}</span
      >{{ scope.props.hourTxt }} <span>{{ scope.props.minutes }}</span
      >{{ scope.props.minutesTxt }} <span>{{ scope.props.seconds }}</span
      >{{ scope.props.secondsTxt }}
    </template>

    <template slot="end-label" slot-scope="scope">
      <span
        v-if="
          scope.props.startLabel !== '' &&
            scope.props.tips &&
            scope.props.labelPosition === 'end'
        "
        ><strong>{{ scope.props.startLabel }}:</strong></span
      >
      <span
        v-if="
          scope.props.endLabel !== '' &&
            !scope.props.tips &&
            scope.props.labelPosition === 'end'
        "
        ><strong>{{ scope.props.endLabel }}:</strong></span
      >
    </template>

      <template slot="end-text" slot-scope="scope">
          <span class="red">
            <strong>{{ scope.props.endText }}</strong>
          </span>
        </template>
  </VueCountdownTimer>
</template>

<script>
export default {
  props: ['inicio', 'fim'],
  methods: {
    endCallBack() {
      this.$emit('end', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.red {
  color: red;
}
</style>
